<div id="registrationTherm" [@fadeAnimation]>
  <div class="registrationTherm__section">
    <div class="registrationTherm__title">{{ 'therm-popup-headline' | translate }}</div>
    @if (store.$userLocale().canDeliver === false) {
      <p>{{ 'no-delivery-txt' | translate }}</p>
    }
    <p>{{ 'therm-popup-text' | translate }}</p>
    <img class="registrationTherm__img" src="../assets/img/measuring-1.jpg" />
  </div>

  <div class="registrationTherm__section">
    <div class="registrationTherm__title">{{ 'therm-popup-headline2' | translate }}</div>
    <div class="registrationTherm__subtitle">{{ 'therm-popup-subheadline' | translate }}</div>
    <p>{{ 'therm-popup-precision-text' | translate }}</p>
    <div class="registrationTherm__subtitle">{{ 'therm-popup-subheadline2' | translate }}</div>
    <p>{{ 'therm-popup-tailored-text' | translate }}</p>
  </div>

  <div class="registrationTherm__section">
    <div class="registrationTherm__title">{{ 'therm-popup-headline3' | translate }}</div>
    <img class="registrationTherm__img" [src]="'assets/img/therm-overview.png'" />
    <div class="registrationTherm__subtitle">{{ 'therm-popup-step1-headline' | translate }}</div>
    <p>{{ 'therm-popup-step1-text' | translate }}</p>
    <div class="registrationTherm__subtitle">{{ 'therm-popup-step2-headline' | translate }}</div>
    <p>{{ 'therm-popup-step2-text' | translate }}</p>
    <div class="registrationTherm__subtitle">{{ 'therm-popup-step3-headline' | translate }}</div>
    <p>{{ 'therm-popup-step3-text' | translate }}</p>
    <img class="registrationTherm__img" src="../assets/img/measuring-2.jpg" />
  </div>

  <div class="registrationTherm__actions">
    @if (store.$userLocale().canDeliver) {
      <app-button
        color="purple"
        uid="registrationTherm__noIDont"
        mixpanelEvent="therm-page-need"
        label=" {{ 'therm-popup-purchase-button' | translate }}"
        (click)="addTherm()"
      />
    }

    @if (store.$userLocale().canDeliver) {
      <app-button
        text="blue"
        uid="registrationTherm__yesIOwn"
        mixpanelEvent="therm-page-own"
        label="{{ 'therm-txt-own' | translate }}"
        (click)="skip()"
      />
    }
  </div>
</div>
