import { transition, trigger, useAnimation } from '@angular/animations'
import { Component, inject, Input } from '@angular/core'
import { SignalStore } from '@src/app/core/store/signalStore'
import { fadeAnimation, staggerAnimation } from '@src/app/core/util/animations.util'

@Component({
  selector: 'app-page-therm',
  templateUrl: './therm.component.html',
  styleUrls: ['./therm.component.scss'],
  animations: [
    trigger('fadeAnimation', [transition('* => *', [useAnimation(fadeAnimation)])]),
    trigger('staggerAnimation', [transition('* => *', [useAnimation(staggerAnimation)])]),
  ],
})
export class ThermComponent {
  store = inject(SignalStore)

  @Input()
  api!: {
    select: (b: boolean) => Promise<boolean>
  }

  async addTherm(): Promise<boolean> {
    return await this.api.select(true)
  }

  async skip(): Promise<boolean> {
    return await this.api.select(false)
  }
}
